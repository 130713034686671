import * as React from "react"
import Layout from "../components/layout";

const Profile = (location) => {
    return (
        <Layout location='{location}' title='Home'>
            <div>
                I'm Paul Michaels, a Software Engineer, Speaker, Author
            </div>
        </Layout>
    );
};

export default Profile;
